<template>
  <b-collapse id="filter-box" class="client-list-filter">
    <div class="card-header py-0 border-0">
      <div class="row">
        <div class="col-12 col-lg-6 mb-5">
          <v-text-field
            label="Search With Talent Name or Email"
            outlined
            dense
            hide-details
            v-model.trim="query.talent"
          ></v-text-field>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-autocomplete
            v-model="selectedClient"
            :items="clientsList"
            :loading="isLoading"
            :search-input.sync="searchQuery"
            @update:search-input="debouncedFetchItems(searchQuery)"
            :no-filter="true"
            chips
            clearable
            hide-details
            hide-selected
            return-object
            dense
            outlined
            label="Search with client name or client Email"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Search to select a
                  <strong>Client</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="grey lighten-2"
                v-on="on"
              >
                <span
                  v-if="item.project_internal_name"
                  v-text="`${item.client_name} - ${item.project_internal_name}`"
                ></span>
                <span v-else v-text="`${item.client_name}`"></span>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${item.client_name} - ${item.client_email}`"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.project_internal_name"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <!-- <div class="col-12 col-lg-6 mb-5">
          <v-text-field
            label="Search With Client Name or Email or Mobile No. or Squad Name"
            outlined
            dense
            hide-details
            v-model.trim="query.client"
          ></v-text-field>
        </div> -->
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="contractStatuses"
            item-text="text"
            item-value="value"
            label="Contract Status"
            dense
            hide-details
            outlined
            v-model="query.status"
          >
          </v-select>
        </div>
        <div class="col-sm-12 col-lg-6 mb-5">
          <v-select
            :items="renewalStates"
            item-text="text"
            item-value="value"
            label="Renewal state"
            outlined
            dense
            hide-details
            multiple
            v-model="query.renewalState"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-dialog
            ref="rangeMenuDialog"
            v-model="rangeMenu"
            :return-value.sync="query.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="query.date"
                icon="mdi-calendar"
                label="Contracts active date range"
                readonly
                dense
                hide-details
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="query.date" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="rangeMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="dataRangeSorting"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-dialog
            ref="rangeExpiryDateMenuDialog"
            v-model="rangeExpiryDateMenu"
            :return-value.sync="query.expiryDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="query.expiryDate"
                icon="mdi-calendar"
                label="Contracts expiry date range"
                readonly
                dense
                hide-details
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="query.expiryDate" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="rangeExpiryDateMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="expiryDateRangeSorting">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <div class="col-sm-12 col-lg-6 mb-5">
          <v-select
            :items="renewalType"
            item-text="text"
            item-value="value"
            label="Renewal Type"
            outlined
            dense
            hide-details
            v-model="query.renewalType"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-6 mb-5">
          <v-select
            :items="accountManagers"
            item-text="name"
            item-value="id"
            label="Account Manager"
            outlined
            dense
            hide-details
            v-model="query.accountManager"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="inRenewalCycleList"
            item-text="text"
            item-value="value"
            label="In renewal cycle"
            dense
            hide-details
            outlined
            v-model="query.inRenewalCycle"
          >
          </v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5"></div>
        <div class="col-6 col-lg-6 mb-5">
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-5 col"
            @click="applyFilter(true)"
          >
            Apply
          </button>
        </div>
        <div class="col-6 col-lg-6 mb-5">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-5 col"
            @click="resetQuery"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash.debounce";

const initQuery = {
  talent: "",
  clientProjectId: "",
  status: null,
  clientStatus: null,
  date: [],
  expiryDate: [],
  renewalState: [],
  renewalType: null,
  accountManager: null,
  inRenewalCycle: null,
};

export default {
  name: "ContractsListFilter",
  data() {
    return {
      date: null,
      rangeMenu: false,
      rangeExpiryDateMenu: false,
      searchQuery: null,
      isLoading: false,
      inRenewalCycleList: [
        {
          text: "Yes",
          value: "1",
        },
        {
          text: "No",
          value: "0",
        },
      ],
      contractStatuses: [
        {
          text: "Future",
          value: "future",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Deleted",
          value: "deleted",
        },
        {
          text: "Ended",
          value: "ended",
        },
      ],
      renewalStates: [
        {
          text: "Pending client action",
          value: "client_pending",
        },
        {
          text: "Pending talent action",
          value: "talent_pending",
        },
        {
          text: "Pending operations",
          value: "operation_pending",
        },
        {
          text: "Rejected by client",
          value: "client_rejected",
        },
        {
          text: "Rejected by talent",
          value: "talent_rejected",
        },
        {
          text: "Renewed",
          value: "renewed",
        },
      ],
      renewalType: [
        {
          text: "Auto",
          value: "auto",
        },
        {
          text: "Manual",
          value: "manual",
        },
      ],
      query: { ...initQuery },
      isLoadingSkills: false,
      selectedClient: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      clientsList: "talentProfile/getClientsList",
      accountManagers: "contracts/getAccountManagers",
    }),
  },
  watch: {
    selectedClient(value) {
      if (value) {
        this.query.clientProjectId = value.project_id;
      } else {
        this.query.client = null;
        this.debouncedFetchItems("");
      }
    },
  },
  async mounted() {
    this.debouncedFetchItems = debounce(this.handleGettingClientsList, 300);
    this.debouncedFetchItems("");
    await this.getAccountManagers();
  },
  methods: {
    ...mapActions({
      getAccountManagers: "contracts/getAccountManagers",
      getClientsList: "talentProfile/getClientsList",
    }),
    handleGettingClientsList(payload) {
      this.isLoading = true;
      this.getClientsList(payload)
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    dataRangeSorting() {
      this.$refs.rangeMenuDialog.save(this.query.date);
      this.query.date = this.query.date.sort().join(" - ");
    },
    expiryDateRangeSorting() {
      this.$refs.rangeExpiryDateMenuDialog.save(this.query.expiryDate);
      this.query.expiryDate = this.query.expiryDate.sort().join(" - ");
    },
    applyFilter(validateFirst) {
      let {
        talent,
        clientProjectId,
        status,
        clientStatus,
        date,
        expiryDate,
        renewalState,
        renewalType,
        accountManager,
        inRenewalCycle,
      } = this.query;
      if (
        validateFirst &&
        !talent &&
        clientProjectId === null &&
        status === null &&
        clientStatus == null &&
        !date.length &&
        !expiryDate.length &&
        !renewalState.length &&
        renewalType === null &&
        accountManager === null &&
        inRenewalCycle === null
      ) {
        this.$toasted.show("Please Add At Least One Filter", {
          type: "error",
          icon: "error",
        });
        return;
      }
      let data = {};
      if (talent) data.talent = talent;
      if (clientProjectId !== null) data.clientProjectId = clientProjectId;
      if (status !== null) data.status = status;
      if (clientStatus !== null) data.clientStatus = clientStatus;
      if (date.length) data.date = date;
      if (expiryDate.length) data.endDate = expiryDate;
      if (renewalState.length) data.renewalState = renewalState;
      if (renewalType !== null) data.renewalType = renewalType;
      if (accountManager !== null) data.accountManager = accountManager;
      if (inRenewalCycle !== null) data.inRenewalCycle = inRenewalCycle;

      this.$emit("applyFilter", data);
    },
    resetQuery() {
      this.selectedClient = null;
      this.query = { ...initQuery };
      this.applyFilter();
    },
  },
};
</script>
